import GATSBY_COMPILED_MDX from "/Users/antonaverin/Dev/Projects/Personal/ARCPanel/ps_ARCPanel/gSite/src/content/_docs/en/docs.mdx";
import React from 'react';
import {injectIntl} from 'react-intl';
import RootLayout from '@components/layout/rootLayout.js';
import DocsPage from '@components/docs/docsPage.js';
import {graphql} from 'gatsby';
import SEO from '@components/seo';
const LocalizedSEO = injectIntl(({preview, json, intl}) => {
  const {messages, locale} = intl;
  return React.createElement(React.Fragment, null, React.createElement(SEO, {
    title: `Docs`,
    pageLanguage: locale,
    desc: `ARCPanel is an extension for Adobe Photoshop CC (2015.5-2020) that solves the pain of building luminosity masks. Let me walk you through details. `
  }), locale == 'ru' && React.createElement(SEO, {
    title: `Документация`,
    pageLanguage: locale,
    desc: `ARCPanel это расширение для Adobe Photoshop CC (2015.5-2020), которое значительно упрощает задачу создания яркостных масок. Давайте посмотрим подробнее.`
  }));
});
const Layout = props => {
  const {locale} = props.pageContext;
  return React.createElement(RootLayout, {
    locale: locale
  }, React.createElement(LocalizedSEO), React.createElement(DocsPage, props));
};
Layout
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Layout, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query DocsQuery($locale: String) {
    mdx(fields: {slug: { eq: "docs" }, locale: { eq: $locale }}) {
      internal {
        contentFilePath
      }
      tableOfContents(maxDepth: 3)
      frontmatter {
        toc
        date(formatString: "MMMM DD, YYYY")
        title
        description
        hashtags
        cover_image {
          publicURL
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            gatsbyImageData(width: 786, layout: CONSTRAINED)
          }
        }
        images {
          publicURL
        }
      }
    }
  }
`;
