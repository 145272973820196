/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ARCMask from "@components/uxppanel/arcmask/arcMask";
import ARCRetouch from "@components/uxppanel/arcretouch/arcretouch";
import ARCSharpen from "@components/uxppanel/arcsharp/arcsharp";
import Source from "@components/uxppanel/arcmask/arcMask_source";
import Masks from "@components/uxppanel/arcmask/arcMask_masks";
import Zones from "@components/uxppanel/arcmask/arcMask_zones";
import Refine from "@components/uxppanel/arcmask/arcMask_refine";
import Apply from "@components/uxppanel/arcmask/arcMask_apply";
import QuickActions from "@components/uxppanel/arcmask/arcMask_quickActions";
import {MaskCalculator} from "@components/uxppanel/arcmask/calculator/arcmask_calculator";
import Tools from "@components/uxppanel/arcretouch/arcretouch_tools";
import FrequencySeparation from "@components/uxppanel/arcretouch/arcretouch_frequencySeparation";
import Orton from "@components/uxppanel/arcretouch/arcretouch_orton";
import DodgeBurn from "@components/uxppanel/arcretouch/arcretouch_dodgeburn";
import {MaskAndEnhance, YouTube, L, PanelGrid} from "@src/style/mdxStyle.js";
import ReactCompareImage from "react-compare-image";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    p: "p",
    blockquote: "blockquote",
    h3: "h3",
    ul: "ul",
    li: "li",
    strong: "strong",
    ol: "ol",
    em: "em",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "docs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#docs",
    "aria-label": "docs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Docs"), "\n", React.createElement(_components.h2, {
    id: "arcpanel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arcpanel",
    "aria-label": "arcpanel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ARCPanel"), "\n", React.createElement(_components.p, null, "ARCPanel is an extension for Adobe Photoshop that solves the pain of building luminosity masks."), "\n", React.createElement(_components.p, null, "Actively supported version is ARCPanel 3.0. It is free and works on Adobe Photoshop CC 2022 and later. ARCPanel Pro enables advanced functionality."), "\n", React.createElement(_components.p, null, "For older versions of Adobe Photoshop (2015.5 - 2022) you can use ARCPanel 2.2. It has a free trial for 7 days and has to be purchased afterwards. All features are always enabled. Is no longer actively developed and will not have new features added."), "\n", React.createElement(_components.p, null, "There are 3 tabs in ARCPanel: ARCMask, ARCRetouch and ARCSharpen"), "\n", React.createElement(MaskAndEnhance, null, React.createElement(ARCMask), React.createElement(ARCRetouch), React.createElement(ARCSharpen)), "\n", React.createElement(_components.p, null, "ARCMask let's you build fast and high quality luminosity, saturation and color masks, refine them and apply to any layer you want."), "\n", React.createElement(_components.p, null, "ARCRetouch is there to help with common tasks like Dodge & Burn, Frequency Separation or Orton Effect."), "\n", React.createElement(_components.p, null, "ARCSharpen will help when your image is ready and needs sharpening for the web."), "\n", React.createElement(_components.p, null, "Keep reading to see all ARCPanel features with video examples - every video is short 10-15 seconds clip without audio, so feel free to press that play button."), "\n", React.createElement(_components.h2, {
    id: "arcmask",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arcmask",
    "aria-label": "arcmask permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ARCMask"), "\n", React.createElement(MaskAndEnhance, null, React.createElement(ARCMask)), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Luminosity masks made easy"), "\n"), "\n", React.createElement(_components.h3, {
    id: "mask-sources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#mask-sources",
    "aria-label": "mask sources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Mask Sources"), "\n", React.createElement(PanelGrid, null, React.createElement(Source)), "\n", React.createElement(_components.p, null, "Mask source is a base for all other masks. By default, Luminosity is used, but you can select other sources to generate, eg. Saturation masks"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Luminosity"), " – regular luminosity mask"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "R"), " – luminosity mask for Red channel"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "G"), " – luminosity mask for Green channel"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "B"), " – luminosity mask for Blue channel"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Saturation"), " – mask, applying to most saturated pixels of the image"), "\n"), "\n", React.createElement(YouTube, {
    video: "oBL3U53_qE4"
  }), "\n", React.createElement(_components.h3, {
    id: "hue-range-masks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hue-range-masks",
    "aria-label": "hue range masks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hue range masks"), "\n", React.createElement(PanelGrid, null, React.createElement(Source)), "\n", React.createElement(_components.p, null, "Hue range mask (or Color mask) is a part of source and is built based on already selected source options: Luminosity or Saturation."), "\n", React.createElement(_components.p, null, "Luminosity is used by default, meaning that colors will be selected based on their luminanse values. Selecting Saturation as the source will change color slider to select based on color saturation values."), "\n", React.createElement(_components.p, null, "After hue mask is applied it itself becomes the Source and can be modified by any masks – that means that you can select a range of colors from the image based on their Saturation values and after that select brightest of them by clicking Lights-3 on Masks"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Color Picker"), " – lets you pick a color from the image to build color mask"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Color Slider"), " – click anywhere on the slider to select a range, drag handles to modify the range. Click Build Maks or Cancel to apply or cancel color mask."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Feather and Boost"), " - move Feather and Boost sliders to better control the selection."), "\n"), "\n", React.createElement(YouTube, {
    video: "VKciVj9xxoE"
  }), "\n", React.createElement(_components.h3, {
    id: "luminosity-range-masks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#luminosity-range-masks",
    "aria-label": "luminosity range masks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Luminosity range masks"), "\n", React.createElement(PanelGrid, null, React.createElement(Masks)), "\n", React.createElement(_components.p, null, "Luminosity is a spectrum that is not limited to Lights 1-5 and other ranges. Luminosity range masks brings flexibility into luminosity mask creation allowing to pick the mask you need by just moving a slider."), "\n", React.createElement(_components.p, null, "Functionality is very similar to Range Masks from Lightroom, but in Photoshop, with the availability of layers, this gives a completely different level of flexibility."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Range picker"), " - lets you pick a luminosity from the image and slider will auto select correct values"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Luminosity slider"), " - controlling two sliders, select any range you want. Having right-most slider fully on the right will give lights. Having left-most slider fully on the left will give darks masks. Having two sliders in the middle will give midtones selections."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Feather and Boost"), " - move Feather and Boost sliders to better control the selection."), "\n"), "\n", React.createElement(YouTube, {
    video: "Nks4wNg8iW0"
  }), "\n", React.createElement(_components.h3, {
    id: "masks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#masks",
    "aria-label": "masks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Masks"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Luminosity / Saturation"), "\n"), "\n", React.createElement(PanelGrid, null, React.createElement(Masks)), "\n", React.createElement(_components.p, null, "Mask is generated based on selected source: Luminosity, Saturation or Color. If no source is selected – Luminosity is used."), "\n", React.createElement(_components.p, null, "Gradient provides a hint on which part of the image will be selected"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Darks from 1 to 6"), " – generates masks for darks parts of the image"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Mids from 1 to 6"), " – generates masks for midtones"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Lights from 1 to 6"), " – generates masks for light parts of the image"), "\n"), "\n", React.createElement(YouTube, {
    video: "HcevGP_EqTs"
  }), "\n", React.createElement(_components.h3, {
    id: "adams-zones-system",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#adams-zones-system",
    "aria-label": "adams zones system permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Adams' Zones System"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Read more about zones ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Zone_System"
  }, "here")), "\n"), "\n", React.createElement(PanelGrid, null, React.createElement(Zones)), "\n", React.createElement(_components.p, null, "Adams' Zones “slice” image into luminosity ranges, selecting only required part of the image for you"), "\n", React.createElement(_components.p, null, "Zone is generated based on selected source. If no source is selected – Luminosity is used"), "\n", React.createElement(_components.p, null, "Gradient provides a hint on which part of the image will be selected"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Zones 0-10"), " – generates Adam's zone masks"), "\n"), "\n", React.createElement(YouTube, {
    video: "yAQcJR79Q9w"
  }), "\n", React.createElement(_components.h3, {
    id: "calculator",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#calculator",
    "aria-label": "calculator permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Calculator"), "\n", React.createElement(PanelGrid, null, React.createElement(MaskCalculator)), "\n", React.createElement(_components.p, null, "Calculator lets you Add, Subtract or Multiply any mask with any mask."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Build some mask,"), "\n", React.createElement(_components.li, null, "Pick operator,"), "\n", React.createElement(_components.li, null, "build another mask,"), "\n", React.createElement(_components.li, null, "press on \"=\""), "\n"), "\n", React.createElement(_components.p, null, "Built mask will be the result of operation you have picked."), "\n", React.createElement(_components.p, null, "You can continue this process until you get the result you want."), "\n", React.createElement(_components.p, null, "Calculator works with all masks, uncluding Hue Range and Luminosity Range."), "\n", React.createElement(YouTube, {
    video: "2-mDgHgYVXw"
  }), "\n", React.createElement(_components.h3, {
    id: "refine",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#refine",
    "aria-label": "refine permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Refine"), "\n", React.createElement(PanelGrid, null, React.createElement(Refine)), "\n", React.createElement(_components.p, null, "Refine lets you apply some changes to the generated mask"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Levels"), " – will invoke Layers dialog allowing to shift light balance of the mask"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Curves"), " – will invoke Curves dialog allowing to refine mask"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Invert"), " – will invert the mask"), "\n"), "\n", React.createElement(YouTube, {
    video: "qmsATy9TmIA"
  }), "\n", React.createElement(_components.h3, {
    id: "apply",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#apply",
    "aria-label": "apply permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Apply"), "\n", React.createElement(PanelGrid, null, React.createElement(Apply)), "\n", React.createElement(_components.p, null, "Apply section lets to set generated mask on the desired layer"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Levels"), " – creates a new Levels adjustment with generated mask set"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Curves"), " – creates a new Curves adjustment with generated mask set"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Selection"), " – makes built mask into an active selection"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "To Layer"), " – sets built mask on the selected layer, overriding existing mask"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Paint mask"), " – allows to paint though built mask over the mask of a selected layer"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Adjustment Layer"), " – lets you pick any Adjustment layer and set mask on it"), "\n"), "\n", React.createElement(YouTube, {
    video: "EY5B9Yjieig"
  }), "\n", React.createElement(_components.h3, {
    id: "paint-mask",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#paint-mask",
    "aria-label": "paint mask permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Paint mask"), "\n", React.createElement(PanelGrid, null, React.createElement(Apply)), "\n", React.createElement(_components.p, null, "Paint mask is a special function, that gives a one-button shortcut to a widely used technique in processing."), "\n", React.createElement(_components.p, null, "Often we want to paint desired effect through a mask. Usually, this requires to:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "build a mask"), "\n", React.createElement(_components.li, null, "make a selection"), "\n", React.createElement(_components.li, null, "hide marching ants"), "\n", React.createElement(_components.li, null, "select a brush"), "\n", React.createElement(_components.li, null, "set foreground color to white"), "\n", React.createElement(_components.li, null, "select mask (set it black if it's missing)"), "\n"), "\n", React.createElement(_components.p, null, "All these steps are done automatically by pressing Paint Mask button. Panel will also conviniently show if there is a selection with hidden ants active at any moment."), "\n", React.createElement(YouTube, {
    video: "vbP2qG1hcuM"
  }), "\n", React.createElement(_components.h3, {
    id: "quick-actions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#quick-actions",
    "aria-label": "quick actions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Quick Actions"), "\n", React.createElement(PanelGrid, null, React.createElement(QuickActions)), "\n", React.createElement(_components.p, null, "Quick actions are small utility shortcuts that can improve your workflow speed"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Clear"), " – clears ARCPanel state, removing selections, channels and active masks"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Quick Blend"), " – when activated, disables preview and directly applies selected mask on the selected layer. Very helpful when blending images together."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Group with white mask"), " – a button to quickly group layers with white mask applied"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Group with black mask"), " – a button to quickly group layers with black mask applied"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Preview on hover"), " – disables or enables preview on hover functionality"), "\n"), "\n", React.createElement(YouTube, {
    video: "6QAccVxVh0Q"
  }), "\n", React.createElement(_components.h3, {
    id: "infinite-color",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#infinite-color",
    "aria-label": "infinite color permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Infinite Color"), "\n", React.createElement(_components.p, null, "One click on Infinite Color button generates a random color grading preset that can be manually adjusted. Keep clicking until you find a pleasing color combination and then tailor it to your needs.\nFeature works best for city and portrait images allowing to quickly get to film-like colors."), "\n", React.createElement(_components.p, null, "More details in ", React.createElement(L, {
    t: "this article",
    h: "https://arcpanel.averin.photo/blog/2020-05-07-arcpanel-2.2-infinite-colors/"
  }), ". You can also learn more about color grading in general in ", React.createElement(L, {
    t: "this article",
    h: "http://localhost:8000/en/blog/2020-07-05-color-grading-from-movies-to-photos/"
  }), "."), "\n", React.createElement(YouTube, {
    video: "8jw6tcPeeGk"
  }), "\n", React.createElement(_components.p, null, "Image on the video provided by ", React.createElement(_components.a, {
    href: "https://www.instagram.com/tell_is_man/"
  }, "Tellisman Svitozar")), "\n", React.createElement(_components.h2, {
    id: "arcretouch",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arcretouch",
    "aria-label": "arcretouch permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ARCRetouch"), "\n", React.createElement(MaskAndEnhance, null, React.createElement(ARCRetouch)), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Useful enhancements at your disposal"), "\n"), "\n", React.createElement(_components.h3, {
    id: "tools",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tools",
    "aria-label": "tools permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tools"), "\n", React.createElement(PanelGrid, null, React.createElement(Tools)), "\n", React.createElement(_components.p, null, "Little tools to make your life easier"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Solar Curve"), " – a special curve that makes it much easier to spot dust, scratches or issues with clone stamp on the image."), "\n"), "\n", React.createElement(YouTube, {
    video: "YYNg92FXAto"
  }), "\n", React.createElement(_components.h3, {
    id: "frequency-separation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#frequency-separation",
    "aria-label": "frequency separation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Frequency Separation"), "\n", React.createElement(PanelGrid, null, React.createElement(FrequencySeparation)), "\n", React.createElement(_components.p, null, "Frequency Separation is a tool that lets you extract texture and modify it independently from other parts of the image.\nMain appliances: smoothening human skin, fixing lens flares, professional product retouching."), "\n", React.createElement(_components.p, null, "ARCRetouch previews both texture and tone(color), letting you select exactly the right frequency you need for your image."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Gaussian Blur"), " gives more conventional results"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Surface Blur"), " works good in portraits"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Median"), " gives much better results and fine-tuning for product retouching."), "\n"), "\n", React.createElement(_components.p, null, "Every method will generate extra layers for quick re-touching of low and high frequencies and a hidden Texture Boost layer to simplify work with skin."), "\n", React.createElement(YouTube, {
    video: "H_26tEGz-TY"
  }), "\n", React.createElement(_components.h3, {
    id: "orton-effect",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#orton-effect",
    "aria-label": "orton effect permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Orton Effect"), "\n", React.createElement(PanelGrid, null, React.createElement(Orton)), "\n", React.createElement(_components.p, null, "Orton Effect is a very popular enhancement brining that magical glow to the pictures"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Orton Heavy"), " – strong and visible Orton Effect"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Orton Soft"), " – a softer version of Orton Effect"), "\n"), "\n", React.createElement(_components.p, null, "Selecting Preserve Darks checkbox will also apply a special mask, that will exclude effect from darkest parts of the image to avoid underexposure."), "\n", React.createElement(YouTube, {
    video: "XOL4SVRDm3Q"
  }), "\n", React.createElement(_components.p, null, "Orton Heavy before/after"), "\n", React.createElement(ReactCompareImage, {
    leftImage: "/res/OrtonBefore.jpg",
    rightImage: "/res/OrtonAfter_Heavy.jpg"
  }), "\n", React.createElement(_components.p, null, "Orton Soft before/after"), "\n", React.createElement(ReactCompareImage, {
    leftImage: "/res/OrtonBefore.jpg",
    rightImage: "/res/OrtonAfter_Soft.jpg"
  }), "\n", React.createElement(_components.h3, {
    id: "dodge--burn",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dodge--burn",
    "aria-label": "dodge  burn permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Dodge & Burn"), "\n", React.createElement(PanelGrid, null, React.createElement(DodgeBurn)), "\n", React.createElement(_components.p, null, "Dodging and Burning is the main post-processing technique. Dodging is brightening, and Burning is darkening parts of the image."), "\n", React.createElement(_components.p, null, "Panel provides 3 Dodge & Burn techniques"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Grey Layers"), " – the most used technique – two 50% grey layers with Overlay and Soft Light blending modes"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Curves"), " – A bit emore advanced technique – 2 curves adjustment layers layered in Luminosity. Curves set the limit of dodge/burn to avoid clipping."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Automatic"), " – Good for portraits. Lets you select Blend-If ranges for lights and darks – this allows to automatically update Dodge and Burn when underlying pictures changes"), "\n"), "\n", React.createElement(YouTube, {
    video: "2fb2bXtxn4E"
  }), "\n", React.createElement(_components.h2, {
    id: "arcsharpen",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arcsharpen",
    "aria-label": "arcsharpen permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ARCSharpen"), "\n", React.createElement(MaskAndEnhance, null, React.createElement(ARCSharpen)), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Free and easy way to make your image ready for web.")), "\n", React.createElement(_components.h3, {
    id: "sharpen",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sharpen",
    "aria-label": "sharpen permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sharpen"), "\n", React.createElement(_components.p, null, "Whether you want to sharpen only current image or all images opened in Photoshop – ARCSharpen can handle it for you."), "\n", React.createElement(_components.p, null, "There are size presets for Instagram and Facebook that give you best image quality for social networks, but you are also welcome to set custom values with \"Fit in\" selected."), "\n", React.createElement(_components.p, null, "Clicking on \"Sharpen for web\" will sharpen opened files, but not save them."), "\n", React.createElement(_components.p, null, "Algorithm is a widely used step size sharpening: first image size is set to double the target, then image is sharpened, downsized to target size and sharpened again – this gives great results that both keep the quality high and make image look great in the browser."), "\n", React.createElement(_components.h3, {
    id: "export",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#export",
    "aria-label": "export permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Export"), "\n", React.createElement(_components.p, null, "If you also would like to export files as sRGB JPG, click on Export checkbox."), "\n", React.createElement(_components.p, null, "You can limit size of the files to a value in kilobytes. During export, ARCSharpen will keep your selected width and height, but will pick a lower quality of jpg compression value to fit size that you want as close as possible."), "\n", React.createElement(_components.p, null, "Select the folder you want to save your images to."), "\n", React.createElement(_components.p, null, "Optionally, set Prefix, Name and Suffix. Prefix will be put in front of every file, Suffix will be put to the end. If you don't set a Name, original filename will be used, otherwise same Name will be applied to every file."), "\n", React.createElement(_components.p, null, "For example, with Prefix ", React.createElement(_components.code, null, "November_"), ", Name ", React.createElement(_components.code, null, "Saxony"), ", Suffix ", React.createElement(_components.code, null, "_Nightsky"), " will give you filenames of ", React.createElement(_components.code, null, "November_Saxony_Nightsky_1"), " and so on, with number in the end increasing for each file. This also happens if when saving file with such name already exists."), "\n", React.createElement(_components.p, null, "Clicking \"Sharpen for web and export\", ARCSharpen will sharpen your images and save into selected folder."), "\n", React.createElement(YouTube, {
    video: "Efz6uB0DxsI"
  }), "\n", React.createElement(_components.h2, {
    id: "if-something-doesnt-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#if-something-doesnt-work",
    "aria-label": "if something doesnt work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "If something doesn't work"), "\n", React.createElement(_components.p, null, "Development is a tricky process and some times you might find a bug."), "\n", React.createElement(_components.p, null, "In that case, please send the following information:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Operating System version"), "\n", React.createElement(_components.li, null, "Adobe Photoshop version"), "\n", React.createElement(_components.li, null, "ARCPanel log files"), "\n"), "\n", React.createElement(_components.p, null, "You can find log files in following locations:"), "\n", React.createElement(_components.p, null, "MacOS: ", React.createElement(_components.code, null, "/Users/<USER>/Library/Logs/Adobe/<Adobe_Photoshop_Version>/"), "\nWindows: ", React.createElement(_components.code, null, "Users\\YOUR_USER\\AppData\\Roaming\\Adobe\\Adobe Photoshop <Adobe_Photoshop_Version>\\Logs")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
